<template>
  <div>
    <pie-chart
      :chart-data="chartData"
      chart-id="current-alert-pie-chart"
      :colors="colors"
      :height="190"
      legendPosition="right"
    ></pie-chart>
  </div>
</template>

<script>
import PieChart from '@/components/chart/PieChart'
import { getAlertCount } from '@/api/alert'
import { alertSeverities } from '@/utils/const'
import { severityColor } from '@/utils'

export default {
  name: 'CurrentAlertCount',
  components: {
    PieChart
  },
  props: {
    deviceId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      chartData: []
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      getAlertCount({
        count_of: 'severity',
        ...this.params,
        source_type: 'network_device',
        source_id: this.deviceId,
        recovered: false
      }).then(res => {
        const data = res.data.data
        alertSeverities.forEach(severity => {
          const obj = data.find(item => item.name === severity)
          this.chartData.push({ name: this.$t(`alert_severity.${severity}`), value: obj ? obj.value : 0 })
        })
      })
    },
    colors (v) {
      return severityColor(v)
    }
  }
}
</script>

<style></style>
