<template>
  <div>
    <div style="height: 24px; margin-bottom: 24px">
      <severity-radio-group
        v-model="currentSeverity"
        style="float: right"
      ></severity-radio-group>
    </div>
    <div class="overflow-scroll-hidden" style="overflow: scroll">
      <alert-timeline
        :severity="currentSeverity"
        :source-id="sourceId"
        source-type="network_device"
        style="margin-top: 8px"
      ></alert-timeline>
    </div>
  </div>
</template>

<script>
import SeverityRadioGroup from '@/components/radio/SeverityRadioGroup'

export default {
  name: 'AlertList',
  components: {
    AlertTimeline: () => import('@/components/timeline/AlertTimeline'),
    SeverityRadioGroup
  },
  props: {
    sourceId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      currentSeverity: 'all'
    }
  }
}
</script>

<style>

</style>
