<template>
  <div class="status-card">
    <a-space :size="16" v-if="detail">
      <statistics-card-icon :icon="detail.icon" style="font-size: 28px;"></statistics-card-icon>
      <div>
        <p style="color: rgba(0,0,0,.85); margin: 0;">{{ detail.name }}</p>
        <span style="color: #B5B5B5; font-weight: 500;line-height: 24px;" v-if="!detail.data.length">无数据</span>
        <p v-else-if="detail.hasStatus">
          <a-tooltip>
            <template slot="title">
              <div v-for="item in detail.data" :key="item.name">
                <span>{{ item.name }}：</span>
                <span>{{
                  item.status ? $t(`source_status.${item.status}`) : '-'
                }}</span>
              </div>
            </template>
            <span style="color:green;font-size: 18px; ">{{ detail.value }}</span><span> / {{detail.total}}</span>
          </a-tooltip>
        </p>
        <p v-else>
          <a-tooltip>
            <template slot="title">
              <div v-for="item in detail.data" :key="item.name">
                <span>{{ item.name }}：</span>
                <span>{{ item.value ? item.value : '-' }}</span>
              </div>
            </template>
            <span>{{ detail.maxValue }}</span>
          </a-tooltip>
        </p>
      </div>
    </a-space>
  </div>
</template>

<script>
import StatisticsCardIcon from '@/components/icon/StatisticsCardIcon'

export default {
  name: 'StatusCard',
  components: {
    StatisticsCardIcon
  },
  props: {
    detail: {
      type: Object
    }
  }
}
</script>

<style lang="less">
.status-card {
  height: 80px;
  background: #F5F9FE;
  border-radius: 8px;
  color: #096DD9;
  text-align: center;
  padding: 16px 4px 0 4px;
  overflow: hidden;

  .ant-statistic-title {
    font-size: 14px;
    color: rgba(0,0,0,.85);
    line-height: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
  }
  .ant-statistic-content-value {
    color: #5FC367;
  }
}
</style>
