<template>
  <div class="ac_ap-detail-container">
    <div class="return-container">
      <a-button
        type="link"
        icon="arrow-left"
        @click="$router.push({ name: 'ACAPList' })"
      >
        返回
      </a-button>
    </div>

    <div style="padding: 16px 24px 24px">
      <a-page-header :title="detail.name" :sub-title="detail.monitor_address"></a-page-header>

      <a-tabs v-model="activeTab" type="card" style="padding-top: 8px">
        <a-tab-pane key="overview" tab="总览">
          <a-row :gutter="16">
            <!-- 基本信息 -->
            <a-col :md="24" :lg="12" :xxl="6" style="margin-bottom: 16px">
              <div
                class="col-container"
                style="height: 290px; position: relative"
              >
                <div class="module-header-fs14" style="margin-bottom: 16px">
                  基本信息
                </div>
                <a-descriptions
                  :column="2"
                  class="base-description overflow-scroll-hidden"
                  style="max-height: 220px; overflow: auto"
                >
                  <a-descriptions-item label="名称">{{ detail.name }}</a-descriptions-item>
                  <a-descriptions-item label="所属群组" :span="2">
                    <a-tag v-for="group in detail.groups" :key="group.id">{{ group.name }}</a-tag>
                  </a-descriptions-item>
                  <a-descriptions-item label="制造商">{{ $t(`hardware_vendor.${detail.vendor}`) }}</a-descriptions-item>
                  <a-descriptions-item label="型号">{{ detail.model }}</a-descriptions-item>
                  <a-descriptions-item label="状态">
                    <source-status-tag :status="detail.status"></source-status-tag>
                  </a-descriptions-item>
                  <a-descriptions-item label="固件版本">{{ detail.firmware_version }}</a-descriptions-item>
                  <a-descriptions-item label="ID">{{ detail.id.replaceAll('-', '') }}</a-descriptions-item>
                  <a-descriptions-item label="IP 地址">{{ detail.monitor_address }}</a-descriptions-item>
                  <a-descriptions-item label="监控端口">{{ detail.monitor_port }}</a-descriptions-item>
                  <a-descriptions-item
                    label="SNMP 版本"
                    :span="2"
                    v-if="displayMore"
                  >
                    {{ $t(`snmp_version.${detail.snmp_version}`) }}
                  </a-descriptions-item>
                  <a-descriptions-item label="创建时间" :span="2" v-if="displayMore">
                    {{ detail.created_at }}
                  </a-descriptions-item>
                  <a-descriptions-item
                    label="更新时间"
                    :span="2"
                    v-if="displayMore"
                  >
                    {{ detail.updated_at }}
                  </a-descriptions-item>
                  <a-descriptions-item
                    label="监控状态"
                    :span="2"
                    v-if="displayMore"
                  >
                    <monitor-status-tag
                      v-for="item in monitorStatuses"
                      :key="item.name"
                      :status="item.value"
                      :name="item.name"
                    ></monitor-status-tag>
                  </a-descriptions-item>
                </a-descriptions>
                <div
                  class="more-btn-container"
                >
                  <a @click="displayMore = !displayMore">
                    <a-icon
                      theme="filled"
                      :type="displayMore ? 'caret-up' : 'caret-down'"
                    ></a-icon>
                  </a>
                </div>
              </div>
            </a-col>
            <!-- 当前告警 -->
            <a-col :md="12" :xxl="6" style="margin-bottom: 16px">
              <div class="col-container" style="height: 290px">
                <div class="module-header-fs14" style="margin-bottom: 16px">
                  当前告警
                </div>
                <current-alert-count
                  :deviceId="detail.id"
                ></current-alert-count>
              </div>
            </a-col>
            <!-- 性能指标 -->
            <a-col :md="12" :xxl="6" style="margin-bottom: 16px">
              <div class="col-container" style="height: 290px">
                <div class="module-header-fs14" style="margin-bottom: 16px">
                  性能指标
                </div>
                <div class="performance-mudule">
                  <div class="icon-container">
                    <statistics-card-icon
                      icon="cpu"
                      style="font-size: 32px"
                    ></statistics-card-icon>
                  </div>
                  <div class="progress-container">
                    <div class="progress-title">CPU使用率</div>
                    <a-progress :percent="cpuUsage" :stroke-width="10">
                      <template #format="percent">
                        <span class="progress-value">{{ percent }}%</span>
                      </template>
                    </a-progress>
                  </div>
                </div>
                <div class="performance-mudule">
                  <div
                    class="icon-container orange"
                    style="background: #fff9eb"
                  >
                    <statistics-card-icon
                      icon="memory"
                      style="font-size: 32px"
                    ></statistics-card-icon>
                  </div>
                  <div class="progress-container">
                    <div class="progress-title">内存使用率</div>
                    <a-progress
                      :percent="memoryUsage"
                      :stroke-width="10"
                      stroke-color="#F7B500"
                    >
                      <template #format="percent">
                        <span class="progress-value orange"
                          >{{ percent }}%</span
                        >
                      </template>
                    </a-progress>
                  </div>
                </div>
              </div>
            </a-col>
            <!-- 硬件指标 -->
            <a-col :md="24" :lg="12" :xxl="6" style="margin-bottom: 16px">
              <div class="col-container" style="height: 290px">
                <div class="module-header-fs14" style="margin-bottom: 32px">
                  硬件指标<span style="font-size: 11px; font-weight: 400;">（正常/总数）</span>
                </div>
                <a-col
                  :span="12"
                  style="margin-bottom: 24px"
                  v-for="item in statusData"
                  :key="item.icon"
                >
                  <status-card :detail="item"></status-card>
                </a-col>
              </div>
            </a-col>
            <!-- AC统计 -->
            <a-col :span="24" style="margin-bottom: 16px">
              <div class="col-container" style="min-height: 220px">
                <div class="module-header-fs14" style="margin-bottom: 32px">
                  AC统计
                </div>
                <a-col :xxl="8" :lg="12" :md="24">
                  <ac-statistics background="#F3F8FD" style="border-right: 1px solid #d8d8d8">
                    <template slot="statisticsValue">
                      <span style="color: #096dd9">{{ apCount }}</span>
                    </template>
                    <template slot="statisticsTitle">AP总数</template>
<!--                    <template slot="countDescription">-->
<!--                      <a-descriptions-item label="正常">22</a-descriptions-item>-->
<!--                      <a-descriptions-item label="告警"><span style="color: #FF5B73">3</span></a-descriptions-item>-->
<!--                      <a-descriptions-item label="无数据">22</a-descriptions-item>-->
<!--                      <a-descriptions-item label="未监控">41</a-descriptions-item>-->
<!--                    </template>-->
                  </ac-statistics>
                </a-col>
                <a-col :xxl="8" :lg="12" :md="24">
                  <ac-statistics background="#F7FCF8" style="border-right: 1px solid #d8d8d8">
                    <template slot="statisticsValue">
                      <span style="color: #5fc367">{{ ssidCount }}</span>
                    </template>
                    <template slot="statisticsTitle">SSID</template>
<!--                    <template slot="countDescription">-->
<!--                      <a-descriptions-item label="2.4G">22</a-descriptions-item>-->
<!--                      <a-descriptions-item label="5G">41</a-descriptions-item>-->
<!--                    </template>-->
                  </ac-statistics>
                </a-col>
                <a-col :xxl="8" :lg="12" :md="24">
                  <ac-statistics background="#F9F9FE">
                    <template slot="statisticsValue">
                      <span style="color: #7e80f7">{{ connectCount }}</span>
                    </template>
                    <template slot="statisticsTitle">终端</template>
<!--                    <template slot="countDescription">-->
<!--                      <a-descriptions-item label="2.4G">22</a-descriptions-item>-->
<!--                      <a-descriptions-item label="5G">41</a-descriptions-item>-->
<!--                    </template>-->
                  </ac-statistics>
                </a-col>
              </div>
            </a-col>
            <!-- 监控图表 -->
            <div style="margin-bottom: 16px; margin-left: 24px">
              <datetime-range
                :allow-clear="false"
                :default-value="datetimeRange"
                @ok="
                  v => {
                    datetimeRange = v
                  }
                "
              ></datetime-range>
            </div>
            <a-col
              :lg="12"
              style="margin-bottom: 16px"
              v-for="item in monitorItems"
              :key="item.key"
            >
              <div
                class="col-container"
                style="height: 343px; border: none; padding: 0"
              >
                <monitor-chart-card
                  :datetime-range="datetimeRange"
                  :history-func="historyFunc"
                  :item="item"
                  :source-id="detail.id"
                ></monitor-chart-card>
              </div>
            </a-col>
          </a-row>
        </a-tab-pane>
        <a-tab-pane key="networkInterface" tab="网口">
          <hardware-network-interface-table
            :data-source="networkInterfaceData"
            :loading="interfaceLoading"
            source-type="network_device"
            :source-id="detail.id"
          ></hardware-network-interface-table>
          <!-- <network-interface-table
            :get-func="getInterfaceListFunc"
            :get-monitor-list-func="getInterfaceMonitorListFunc"
            source-type="network_device"
            :source-id="detail.id"
          ></network-interface-table> -->
        </a-tab-pane>
        <a-tab-pane key="apList" tab="AP列表">
          <ap-table :acId="detail.id" class="scroll-hidden-table"></ap-table>
        </a-tab-pane>
        <a-tab-pane key="unrecovered" tab="当前告警">
          <alert-list :source-id="detail.id"></alert-list>
        </a-tab-pane>
        <a-tab-pane key="monitor" tab="监控指标">
          <monitor-item-table
            :get-func="getItemListFunc"
            :history-func="historyFunc"
            :source-id="detail.id"
          ></monitor-item-table>
        </a-tab-pane>
        <a-tab-pane key="rules" tab="告警规则">
          <monitor-trigger-table
            :get-func="getTriggerFunc"
            :source-id="detail.id"
            :update-func="updateFunc"
          ></monitor-trigger-table>
        </a-tab-pane>
        <a-tab-pane key="alert" tab="历史告警">
          <div style="padding: 0 16px; flex-flow: nowrap; height: calc(100vh - 80px); overflow: auto">
            <alert-table source-type="network_device" :source-id="detail.id"></alert-table>
          </div>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import {
  getNetworkDevice,
  getNetworkDeviceFanList,
  getNetworkDeviceMonitorHistory,
  getNetworkDeviceMonitorItemList,
  getNetworkDeviceMonitorStatusList,
  getNetworkDeviceMonitorTriggerList,
  getNetworkDeviceNetworkInterfaceList,
  getNetworkDevicePSUList,
  getNetworkDeviceTemperatureList,
  updateNetworkDeviceMonitorTrigger
} from '@/api/network-device'
import DatetimeRange from '@/components/DatetimeRange'
import MonitorChartCard from '@/components/card/MonitorChartCard'
import StatusCard from './modules/StatusCard'
import StatisticsCardIcon from '@/components/icon/StatisticsCardIcon'
import MonitorTriggerTable from '@/components/table/MonitorTriggerTable'
import MonitorItemTable from '@/components/table/MonitorItemTable'
import SourceStatusTag from '@/components/tag/SourceStatusTag'
import MonitorStatusTag from '@/components/tag/MonitorStatusTag'
import CurrentAlertCount from './modules/CurrentAlertCount'
import AlertList from './modules/AlertList'
import { sourceStatuses } from '@/utils/const'
import HardwareNetworkInterfaceTable from '@/components/table/HardwareNetworkInterfaceTable.vue'
import AlertTable from '@/components/table/AlertTable.vue'

export default {
  name: 'ACAPDetail',
  components: {
    AlertTable,
    MonitorChartCard,
    DatetimeRange,
    StatusCard,
    StatisticsCardIcon,
    'ac-statistics': () => import('./modules/ACStatisticsCard'),
    'ap-table': () => import('./modules/APTabe'),
    MonitorTriggerTable,
    MonitorItemTable,
    SourceStatusTag,
    MonitorStatusTag,
    CurrentAlertCount,
    AlertList,
    HardwareNetworkInterfaceTable
  },
  data () {
    return {
      displayMore: false,
      activeTab: 'overview',
      historyFunc: getNetworkDeviceMonitorHistory,
      updateFunc: updateNetworkDeviceMonitorTrigger,
      getTriggerFunc: getNetworkDeviceMonitorTriggerList,
      getItemListFunc: getNetworkDeviceMonitorItemList,
      getInterfaceListFunc: getNetworkDeviceNetworkInterfaceList,
      getInterfaceMonitorListFunc: getNetworkDeviceMonitorHistory,
      datetimeRange: [moment().subtract(6, 'hour'), moment()],
      monitorItems: [
        {
          key: 'icmppingsec',
          title: 'ICMP 延迟 (秒)'
        },
        {
          key: 'icmppingloss',
          title: 'ICMP 丢包率 (%)'
        },
        {
          key: 'system.cpu.util',
          title: 'CPU 使用率 (%)'
        },
        {
          key: 'vm.memory.util',
          title: '内存使用率 (%)'
        }
      ],
      statusData: [
        { icon: 'power', name: '电源', value: 0, total: 0, hasStatus: true, data: [], maxValue: 0 },
        { icon: 'fan', name: '风扇', value: 0, total: 0, hasStatus: false, data: [], maxValue: 0 },
        { icon: 'temperature', name: '温度', hasStatus: false, data: [], maxValue: 0 }
      ],
      count: {
        alert: 0
      },
      detail: {
        id: this.$route.params.id
      },
      monitorStatuses: [],
      networkInterfaceData: [],
      interfaceLoading: false,
      cpuUsage: 0,
      memoryUsage: 0,
      apCount: 0,
      ssidCount: 0,
      connectCount: 0
    }
  },
  mounted () {
    this.fetch()
    this.fetchInterface()
  },
  methods: {
    fetch () {
      getNetworkDevice(this.detail.id).then(res => {
        this.detail = res.data
      })
      getNetworkDeviceMonitorStatusList(this.detail.id).then(res => {
        this.monitorStatuses = res.data.data
      })
      getNetworkDeviceMonitorItemList(this.detail.id, { key: 'system.cpu.util' }).then(res => {
        if (res.data.data.length) { this.cpuUsage = res.data.data[0].value * 1 }
      })
      getNetworkDeviceMonitorItemList(this.detail.id, { key: 'vm.memory.util' }).then(res => {
        if (res.data.data.length) { this.memoryUsage = res.data.data[0].value * 1 }
      })
      getNetworkDeviceFanList(this.detail.id).then(res => {
        const data = res.data.data
        if (data.length) {
          Promise.all(data.map(item => new Promise((resolve, reject) => {
            getNetworkDeviceMonitorItemList(this.detail.id, { key: `sensor.fan.value[${item.name}]` }).then(res => {
              resolve(res.data.data[0])
            }).catch(err => reject(err))
          }))).then(res => {
            this.statusData[1].maxValue = this.getMaxValue(res) + ' ' + res[0].unit
            this.statusData[1].data = data.map((item, index) => {
              return { name: item.name, value: res[index].value + ' ' + res[index].unit, status: item.status }
            })
          })
        }
      })
      getNetworkDevicePSUList(this.detail.id).then(res => {
        const data = res.data.data
        if (data.length) {
          this.statusData[0].hasStatus = this.hasStatus(data)
          if (this.statusData[0].hasStatus) {
            this.statusData[0].value = this.getStatusCount(data)
            this.statusData[0].total = data.length
          } else {
            this.statusData[0].maxValue = this.getMaxValue(data) + ' W'
          }
          this.statusData[0].data = data.map(item => {
            return { name: item.name, value: item.value + ' W', status: item.status }
          })
        }
      })
      getNetworkDeviceTemperatureList(this.detail.id).then(res => {
        const data = res.data.data
        if (data.length) {
          Promise.all(data.map(item => new Promise((resolve, reject) => {
            getNetworkDeviceMonitorItemList(this.detail.id, { key: `sensor.temp.value[${item.name}]` }).then(res => {
              resolve(res.data.data[0])
            }).catch(err => reject(err))
          }))).then(res => {
            this.statusData[2].maxValue = this.getMaxValue(res) + ' ' + res[0].unit
            this.statusData[2].data = data.map((item, index) => {
              return { name: item.name, value: res[index].value + ' ' + res[index].unit, status: item.status }
            })
          })
        }
      })
      getNetworkDeviceMonitorItemList(this.detail.id, { key: 'connect.count[ap]' }).then(res => {
        if (res.data.data.length) { this.apCount = res.data.data[0].value || 0 }
      })
      getNetworkDeviceMonitorItemList(this.detail.id, { key: 'system.ssid' }).then(res => {
        if (res.data.data.length) { this.ssidCount = res.data.data[0].value || 0 }
      })
      getNetworkDeviceMonitorItemList(this.detail.id, { key: 'connect.count[station]' }).then(res => {
        if (res.data.data.length) { this.connectCount = res.data.data[0].value || 0 }
      })
    },
    fetchInterface () {
      this.networkInterfaceData = []
      this.interfaceLoading = true
      getNetworkDeviceNetworkInterfaceList(this.detail.id)
        .then(res => {
          const data = res.data
          if (data && data.data.length) {
            data.data.forEach(item => {
              const obj = {
                id: item.id,
                name: item.name,
                index: item.index,
                interface_type: item.interface_type,
                status: item.status,
                speed: 0,
                net_if_in: 0,
                net_if_out: 0
              }
              this.getKeys(item.index).forEach(keyObj => {
                getNetworkDeviceMonitorHistory(this.detail.id, {
                  key: keyObj.key
                }).then(res1 => {
                  obj[keyObj.dataIndex] = res1.data.data.length
                    ? res1.data.data[0].value
                    : 0
                })
              })
              this.networkInterfaceData.push(obj)
            })
          }
        }).finally(() => {
          this.interfaceLoading = false
        })
    },
    getKeys (index) {
      return [
        { key: `net.if.speed[ifHighSpeed.${index}]`, dataIndex: 'speed' },
        { key: `net.if.in[ifHCInOctets.${index}]`, dataIndex: 'net_if_in' },
        { key: `net.if.out[ifHCOutOctets.${index}]`, dataIndex: 'net_if_out' }
      ]
    },
    getStatusCount (data) {
      let count = 0
      data.forEach(item => {
        if (item.status === 'normal') count++
      })
      return count
    },
    getMaxValue (data) {
      return data.reduce((max, obj) => {
        return obj.value > max ? obj.value : max
      }, data[0].value)
    },
    hasStatus (data) {
      return data.some(
        item =>
          sourceStatuses.findIndex(status => status === item.status) !== -1
      )
    }
  }
}
</script>

<style lang="less">
.ac_ap-detail-container {
  border-radius: 8px;
  background: #fff;
  min-height: calc(100vh - 158px);
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 8px;

  .return-container {
    height: 56px;
    border-bottom: 1px solid #d9d9d9;
    line-height: 56px;
  }

  .ant-page-header {
    box-shadow: none !important;
  }

  .ant-tabs-bar {
    padding-left: 20px;
  }

  .col-container {
    overflow: hidden;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    padding: 14px 12px;
    .ant-card {
      box-shadow: none !important;
      border: 1px solid #d9d9d9;
    }
    // 基本信息
    .base-description {
      .ant-descriptions-item-label {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.5);
        line-height: 20px;
      }
      .ant-descriptions-item-content {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.85);
        line-height: 20px;
      }
    }
    // 性能指标
    .performance-mudule {
      display: flex;
      align-items: center;
      margin: 40px 4px 40px 10px;
      .icon-container {
        width: 60px;
        height: 60px;
        line-height: 72px;
        background: #f5f9fe;
        color: #096dd9;
        border-radius: 8px;
        text-align: center;
        margin-right: 16px;
      }
      .progress-container {
        flex: 1;
        padding-right: 14px;
        .progress-value {
          font-size: 18px;
          font-weight: 500;
          color: #096dd9;
        }
        .progress-title {
          font-size: 16px;
          line-height: 28px;
        }
      }
      .orange {
        color: #f7b500 !important;
      }
    }

    .more-btn-container {
      font-size: 12px;
      color: #096dd9;
      position: absolute;
      bottom: 0px;
      width: 100%;
      text-align: center;
      left: 0px;
      background: #fff;
      line-height: 24px;
    }
  }
}
@keyframes up-down {
  from {
    bottom: -1px;
  }
  to {
    bottom: 3px;
  }
}
</style>
